<template>
  <div>
    <div class="promocao-adicionar">
      <div class="conteudo-interno">
        <div class="nova-comunicacao-container">
          <h1>Adicionar Ranking</h1>
          <FormularioRanking />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { cadastrarPromocoes } from "@/services/promocoes";
const FormularioRanking = () => import("@/components/elementos/paginas/FormularioRanking.vue");
export default {
  name: "RankingAdicionar",
  components: {
    FormularioRanking,
  },
};
</script>

<style lang="scss">
.promocao-adicionar {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
</style>
